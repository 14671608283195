<template>
	<div class="inner">
		<img src="../assets/contact-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">联系我们</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Contact Us</div>
			</div>
			<div class="ft24 pt20 pb30">{{contact.name}}</div>
			<div>
				<el-row>
					<el-col :span="8" v-for="(item,index) in contactList" :key="index">
						<div class="pt36 ft20">
							<span>{{item.linkName}}:{{item.linkPhone}}</span>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="cntact-inner">
			<el-row>
				<el-col :span="13">
					<img src="../assets/contact-img.png" width="100%" />
				</el-col>
				<el-col :span="11">
					<div class="right-box">
						<div class="ft22">
							<img src="../assets/contact-icon1.png" />
							<span class="ml14">{{contact.companyTel}}</span>
						</div>
						<div class="ft22 pt30">
							<img src="../assets/contact-icon2.png" />
							<span class="ml14">{{contact.email}}</span>
						</div>
						<div class="ft22 pt30">
							<img src="../assets/contact-icon3.png" />
							<span class="ml14">{{contact.webSite}}</span>
						</div>
						<div class="ft22 pt30 l16">
							<img src="../assets/contact-icon4.png" />
							<span class="ml14">{{contact.address}}</span>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">公司地址</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Company Address</div>
			</div>
			<el-amap class="amap-box" :center="center">
				<el-amap-marker :position="center"> </el-amap-marker>
			</el-amap>
		</div>
	</div>
</template>

<script>
	import { contactInfo } from '@/api/index.js'
	export default {
		data() {
			return{
				center: [117.772955,39.007446],
				icon: require('@/assets/site.png'),
				contact:{
					name: '',
					companyTel: '',
					email: '',
					webSite: '',
					address: ''
				},
				contactList: [],
			}
		},
		created() {
			this.getContactInfo()
		},
		methods:{
			getContactInfo(){
				contactInfo().then(res=>{
					if(res.code === 200){
						this.contact = {
							name: res.data.name,
							companyTel: res.data.companyTel,
							email: res.data.email,
							webSite: res.data.webSite,
							address: res.data.address
						}
						this.contactList = res.data.contactList;
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.tools-list:hover {
		color: #b4232f;
		transform: translateX(4px);
	}

	.container {
		margin-bottom: 80px;
	}
	.cntact-inner{
		background-color: #f2f2f2;
	}
	.right-box{
		height: 100%;
		padding-left: 10%;
		margin-top: 14%;
	}
	.right-box img{display: inline-block;vertical-align: middle;}
	.amap-box{
		height:657px ;
		margin-bottom: 50px;
	}
</style>
